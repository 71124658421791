@import 'abstracts';

.p-checkbox-box,
.p-checkbox-box:hover,
.p-checkbox-box.p-highlight,
.p-checkbox-box.p-highlight:hover {
  height: 1rem;
  width: 1rem;

  .p-icon-wrapper {
    @include checkBoxIcon();
  }
}
