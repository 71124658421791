/* Base */
@import './base/reset';
@import './base/alignment';

/* Vendor */
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/bootstrap';

/* Components */
@import './components/buttons';
@import './components/calendars';
@import './components/cards';
@import './components/checkbox';
@import './components/confirm-dialogs';
@import './components/custom-row';
@import './components/dialogs';
@import './components/dropdowns';
@import './components/editors';
@import './components/inputs';
@import './components/input_switches';
@import './components/lists';
@import './components/loader';
@import './components/overlay-panel';
@import './components/paginators';
@import './components/right-sidebar';
@import './components/sidebar-menu';
@import './components/tables';
@import './components/toast';
@import './components/tooltip';

/* Theme */
@import './base/radboud-theme';
