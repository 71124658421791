@import 'abstracts';

cap-input-switch .form-element {
  display: flex;
  margin-bottom: 1rem;
  padding-left: 1rem;

  .p-inputswitch {
    margin-right: 1rem;

    span.p-inputswitch-slider:before {
      height: 1.5em;
      width: 1.5em;
      left: 0.2em;
      bottom: 0.2em;
      margin-top: -0.62rem;
    }
  }
}

cap-input-switch.form-input-switch .form-element {
  display: flex;
  margin-bottom: 1rem;
  padding-left: 0;
  flex-flow: column-reverse nowrap;
}

body cap-input-switch .p-inputswitch.p-disabled {
  background: none;
  box-shadow: none;
}
