@import 'abstracts';

.radboud-overlay-panel.p-overlaypanel {
  width: 300px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 13px 0 rgba($black, 0.15);

  .p-overlaypanel-content {
    padding: 0;
  }

  .radboud-overlay-panel__list {
    color: $font-color;

    &__item {
      display: flex;
      align-items: center;
      padding: 1rem;
      font-size: 14px;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      &__icon {
        font-size: 18px;
      }
    }
  }
}

.radboud-overlay-panel.radboud-overlay-panel-tooltip.p-overlaypanel {
  width: 325px;

  .p-overlaypanel-content {
    border-top: 2px solid var(--cap-primary-color, $primary);
    padding: 0;
  }

  .radboud-overlay-panel-tooltip__header {
    padding: 1rem;
    border-bottom: 1px solid $border-color;

    &__title {
      color: $font-color;
      font-size: 15px;
      font-weight: 600;
    }

    &__subtitle {
      margin-top: 0.3rem;
      color: $font-color;
      font-size: 13px;
      font-weight: 300;
    }
  }

  .radboud-overlay-panel-tooltip__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left,
    &__right {
      flex: 1;
      padding: 0 1rem;
      margin: 1rem 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;

      &__icon {
        padding: 0.5rem;
        color: rgba(var(--cap-primary-color-rgb, $primary-rgb), 0.5);
        font-size: 20px;
      }

      &__text {
        color: $font-color;
        font-size: 11px;
        font-weight: 500;
      }
    }

    &__left {
      border-right: 1px solid $border-color;
    }
  }
}

body .radboud-overlaypanel.p-overlaypanel {
  border: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);

  .p-overlaypanel-content {
    padding: 0;

    .panel {
      height: auto;
      padding: 0;
      border-radius: 5px;

      .menu-item {
        margin: 0;
        padding: 15px 5px;
        border-bottom: 1px solid $border-color;
        cursor: pointer;

        &:hover {
          transition: ease-in-out all 0.15s;
          background-color: $border-color;

          .title {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .title {
          font-size: 1em;
          white-space: nowrap;
          color: $overlaypanel-text-color;
          font-weight: 600;
        }

        .subtitle {
          font-size: 12px;
          margin-top: 3px;
          white-space: nowrap;
          color: $overlaypanel-text-color;
          font-weight: 500;
        }

        i {
          color: var(--cap-primary-color, $primary);
          font-size: 1.4rem;
          cursor: default;
        }
      }
    }
  }
}

.page-filters cap-dropdown .p-scroller {
  min-height: 200px;
}
