@import 'abstracts';

cap-card .card {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  background-color: $white;
  padding: 0;

  .card__header {
    border-bottom: 1px solid $border-color;
    padding: 0;

    .radboud-card-header {
      padding: 1.75rem;
      color: $font-color;
      font-size: 18px;
      font-weight: bold;

      i.fas {
        color: var(--cap-primary-color, $primary);
        margin-right: 0.5rem;
      }

      cap-button .cap-icon-button {
        position: absolute;
        top: 1rem;
        right: 1.75rem;
        font-weight: 500;
        padding: 3px 17.5px;
      }
    }
  }

  .card__content {
    padding: 1.75rem;

    formly-form {
      .invalid-feedback {
        display: none !important; /* stylelint-disable declaration-no-important */
      }
    }
  }

  .card__content.content--gutter {
    margin-top: 0;
  }
}

.horizontal-form-card {
  cap-card .card {
    padding: 1rem 2rem;
    flex-direction: row;
    align-items: center;

    .card__header {
      border-bottom: none;
      border-right: 1px solid $border-color;
      padding: 0.5rem 1.5rem 0.5rem 0;

      .radboud-card-header {
        padding: 0;
      }
    }

    .card__content {
      padding: 0 0 0 1.5rem;
      margin-top: 0;
      min-width: 60%;

      em-wrapper-form-field {
        div.form-group label.d-block {
          display: flex !important;
        }

        em-input-formly {
          width: inherit;

          div.form-element {
            display: flex;

            .p-inputgroup {
              margin-right: 1rem;
            }
          }
        }
      }

      formly-form {
        .form-group,
        .form-group:hover {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          width: 100%;

          label {
            margin-right: 2rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

formly-field.hidden {
  display: none;
}
