@import 'abstracts';

$footer-height: 70px;
$header-height: 80px;

body p-sidebar .radboud-right-sidebar {
  min-width: 400px;
  padding: 0;
  border: none;

  .p-sidebar-content {
    height: 100%;
  }

  &__header {
    background: linear-gradient(-225deg, rgb(var(--cap-primary-color-rgb, $primary-rgb)) 0%, rgba(var(--cap-primary-color-rgb, $primary-rgb), 0.7) 100%);
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 30px;
    color: $white;
    font-weight: 500;
    position: relative;
    font-size: 1.3em;

    i {
      cursor: pointer;
      font-size: 1em;
    }
  }

  &__content {
    padding: 30px;
    overflow-y: auto;
    height: calc(100vh - #{$footer-height + $header-height});
  }

  &__footer {
    background: $white;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    padding-top: 30px;
    margin: 20px;
    height: $footer-height;
  }
}
