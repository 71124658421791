@import 'abstracts';
@import 'emendis.theme';
@import 'styles';

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: $green;
}

.login-screen .login-form {
  .title {
    margin-bottom: 1rem;
  }

  cap-input-switch div.form-element {
    margin-top: 1rem;
  }
}

.login-wrapper .content-right {
  height: auto;
}

nav.navbar .search-bar {
  flex: 2;

  i {
    font-size: 16px;
  }

  cap-validator {
    display: none;
  }
}

app-sidebar p-accordiontab .p-icon-wrapper {
  display: none;
}

.page-wrapper {
  &__heading {
    display: flex;
    justify-content: space-between;
    color: var(--cap-primary-color, $primary);
    font-size: 26px;
    font-weight: bold;

    h1 {
      font-size: inherit;
      font-weight: inherit;
    }

    &__link i:hover {
      transition: transform 0.2s ease;
      transform: translateX(-3px);
      cursor: pointer;
    }

    &__buttons {
      display: flex;

      .cap-button.cap-icon-button {
        padding: 5px 15px;
      }

      &__button,
      .cap-button {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  &__content {
    margin: 2.5rem 0 1rem;
    position: relative;

    &__no-results {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.color-primary {
  color: var(--cap-primary-color, $primary);
}

.color-success {
  color: $success;
}

.color-danger {
  color: $danger;
}

.color-warning {
  color: $warning;
}

.font-color-primary {
  color: $font-color;
}

.font-color-light {
  color: $font-color-light;
}

cap-editor {
  &.ng-invalid .ql-container,
  &.ng-invalid .ql-toolbar {
    border-color: $danger !important;
  }

  .cap-editor {
    .ql-container {
      .ql-tooltip {
        z-index: 100;
      }
    }
  }

  quill-editor .ql-editor {
    min-height: 200px;
    max-height: 300px;
  }
}
