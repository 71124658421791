$aligns: left, center, right;
$align-items: flex-start, flex-end, center, stretch, baseline;

@each $align in $aligns {
  .justify-#{$align} {
    justify-content: $align;
  }
  .text-#{$align} {
    text-align: $align;
  }
}

@each $align-item in $align-items {
  .align-#{$align-item} {
    align-items: $align-item;
  }
}
