@import 'abstracts';

.radboud-confirm-dialog {
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 5px 13px 0 rgba($black, 0.2);
  width: 300px;

  .p-dialog-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: $white;
    padding: 1rem;

    .p-dialog-title {
      font-size: 18px;
    }
  }

  div.p-dialog-titlebar,
  div.p-dialog-content,
  div.p-dialog-footer {
    border: none;
    border-radius: inherit;
    text-align: center;
  }

  div.p-dialog-titlebar {
    @include straightBottom();
    background: $white;
    color: $primary;
    font-family: $font-semi-bold;

    span {
      text-align: center;
      display: inline;
      font-size: 15px;
      font-weight: 600;
    }

    .p-dialog-titlebar-close span {
      font-size: 1em;
    }
  }

  div.p-dialog-content {
    padding: 50px 0;
    text-align: center;
    font-size: 16px;
    line-height: normal;
  }
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span {
  line-height: 24px;
  text-align: left;
}
