@import 'abstracts';

body .bordered-table cap-info-table .cap-info-table tbody cap-info-table-row tr.cap-info-table__row td {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  &:first-child {
    border-left: 1px solid $border-color;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-right: 1px solid $border-color;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

body cap-info-table .cap-info-table.p-datatable {
  thead {
    &.p-datatable-thead {
      cap-info-table-header {
        tr {
          th {
            &.cap-info-table__header {
              &.th_remarks-header {
                .remarks-header {
                  width: 10rem;
                }
              }
            }
          }
        }
      }
    }
  }

  tbody.p-datatable-tbody {
    cap-info-table-row {
      tr.cap-info-table__row {
        td {
          &.cap-info-table__column-remarks {
            &.remarks-cell {
              white-space: pre-wrap;

              .cap-cell-tooltip {
                white-space: pre-wrap;
                padding: 1rem 0;
              }
            }
          }
        }
      }
    }
  }
}

::ng-deep {
  .p-paginator-element {
    width: 32px;
    min-width: unset;
    padding: 0;
  }

  .p-paginator-icon {
    font-size: 14px;
  }
}
