@import 'abstracts';

// Fix: avoid content jump/glitching when hovering a label with tooltip (ticket: WUR-94)
body {
  overflow-y: scroll;
}

body .p-tooltip,
div[role="tooltip"] {
  background-color: white;
  box-shadow: 0 2px 4px var(--cap-primary-color-shadow);
  max-width: 300px;
}

body .p-tooltip .p-tooltip-text {
  background-color: white;
  color: $font-color;
}

body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
  display: none;
}
