@import 'abstracts';

.tox.tox-tinymce {
  min-height: 500px;
}

body .p-editor-container .ql-stroke {
  stroke: #444;
}

.readonly-editor {
  background-color: #eee;
  border: 1px solid $input-border-color;
  border-radius: 5px;
  padding: 15px;
  color: $body-color;
  line-height: 19px;
}

body p-editor div.p-editor-container div.p-editor-content {
  border-radius: 3px;
  overflow: hidden;
}

body p-editor div.p-editor-container div.p-editor-toolbar {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.editor-disabled {
  div.p-editor-toolbar,
  .ql-toolbar {
    display: none;
  }

  .image-uploading,
  img {
    pointer-events: none;
    user-select: none;
  }

  div.p-editor-content.ql-container.ql-snow,
  .ql-container.ql-snow.ql-disabled {
    background-color: #eee;
    border-top: 1px solid #c8c8c8;
    border-radius: 5px;

    .image-uploading,
    img {
      pointer-events: none;
      user-select: none;
    }
  }
}

.image-uploading img,
.like-textarea img,
.collapsable-card-content img {
  height: auto;
  max-width: 100%;
}

.like-textarea {
  a {
    color: var(--cap-primary-color) !important;
    text-decoration: underline;
  }

  ul {
    margin: 0;
    padding-left: 18px;
  }
}

.ql-editor {
  a {
    color: var(--cap-primary-color) !important;
    text-decoration: underline;
  }

  ul {
    margin: 0;
    padding-left: 0;

    li {
      padding-left: 10px !important;
      font-size: 0.9rem;
    }
  }

  p {
    font-size: 0.9rem;
  }
}

app-device-detail a {
  color: var(--cap-primary-color) !important;
  text-decoration: underline;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.ql-undo {
  &:after {
    @include setFontAwesomeProIcon('\f0e2', 400);
  }
}

.ql-redo {
  &:after {
    @include setFontAwesomeProIcon('\f01e', 400);
  }
}

.ql-undo:hover::after,
.ql-redo:hover::after, {
  color: $white;
}
