@import 'abstracts';

body cap-calendar p-calendar .p-calendar.cap-calendar {
  &:focus {
    outline: none;
  }

  .p-datepicker {
    min-width: unset;
    padding: 0;
    border: 1px solid $border-color;
    border-radius: 3px;

    &:focus {
      outline: none;
    }

    .p-datepicker-group:focus,
    .p-datepicker-group-container:focus {
      outline: none;
    }
  }

  .p-datepicker-header {
    padding: 0.75rem 1.5rem;

    &:focus {
      outline: none;
    }

    .p-datepicker-title {
      font-size: 16px;
      font-weight: 600;
      color: $font-color;
    }
  }

  .p-datepicker-calendar-container {
    padding: 0 1.5rem 1.5rem;

    &:focus {
      outline: none;
    }

    td span {
      border-radius: 30px;

      &:hover:not(.p-disabled):not(.p-highlight) {
        background-color: $border-color;
      }
    }

    .p-datepicker-today {
      span {
        background-color: transparent;
        border: 1px solid var(--cap-primary-color, $primary);
        color: var(--cap-primary-color, $primary);
      }

      .p-highlight {
        background-color: var(--cap-primary-color, $primary);
        color: $white;
      }

      &:focus {
        outline: none;
      }
    }

    .p-datepicker-other-month span.p-disabled {
      color: $font-color-light;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

body cap-calendar p-calendar .p-calendar.cap-calendar.radboud-inline-calendar {
  position: relative;

  .p-datepicker,
  .p-datepicker-group-container {
    width: 100%;
  }

  .p-datepicker-header {
    .p-datepicker-prev {
      position: absolute;
      top: 15px;
      right: 75px;
    }

    .p-datepicker-next {
      position: absolute;
      top: 15px;
      right: 30px;
    }
  }
}

body cap-calendar div.form-element:focus,
body cap-calendar p-calendar:focus {
  outline: none;
}

.cap-calendar.p-calendar.cap-rounded.has-icon:hover input,
.cap-calendar.p-calendar.cap-rounded.has-icon:hover input:enabled:hover:not(.p-state-error),
.cap-calendar.p-calendar.cap-rounded.has-icon input,
.cap-calendar.p-calendar.cap-rounded.has-icon input:enabled:hover:not(.p-state-error) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 37px;
  height: 33px;
  border-color: $border-color;
  font-size: 1rem;
  color: #333;

  &:focus,
  &:hover {
    outline: none;
    border-color: var(--cap-primary-color, $primary);
    cursor: pointer;
  }
}

body .p-datepicker .p-datepicker-buttonbar button {
  background-color: var(--cap-primary-color, $primary);
  color: $white;
  border: unset;
}
