@import 'abstracts';

body button.p-button.cap-button {
  border-radius: 5px;
  border: 1px;
  padding: 5px 35px;
}

body button.cap-button.cap-rounded {
  border-radius: 50px;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 17.5px;
}

body button.cap-button .p-button-icon-right {
  padding-right: 0;
  padding-left: 0.5rem;
}

body button.cap-button.cap-small {
  font-size: 11.5px;
  font-weight: 600;
}

body button.cap-button.cap-icon-button {
  padding: 5px 15px;
}

body button.cap-button.cap-rounded-icon-button {
  padding: 2.5px 8.5px;
  border-radius: 25px;
  font-size: 14px;

  &:hover {
    transition: ease-in-out all 0.15s;
  }
}

body button.cap-button.primary.cap-rounded-icon-button:hover {
  text-decoration: none;
  color: var(--cap-primary-color, $primary);
  background-color: $white;
  border-color: var(--cap-primary-color, $primary);
}

body button.cap-button.secondary.cap-rounded-icon-button:hover {
  text-decoration: none;
  color: $white;
  background-color: var(--cap-primary-color, $primary);
}

body button.cap-button.cap-rounded-icon-button.cap-icon-button-small {
  padding: 0 6px;
}

body cap-select-button p-selectbutton .cap-select-button.p-selectbutton {
  * {
    outline: none;
  }

  .p-button,
  .p-button:hover,
  .p-button:not(.p-disabled):not(.p-highlight):hover {
    padding: 9px 25px;
  }

  &.cap-card-select-button {
    position: absolute;
    top: 1rem;
    right: 1.75rem;
    z-index: 1;
  }
}

body cap-login cap-button .cap-button.p-button,
body cap-forgot-password cap-button .cap-button.p-button {
  padding: 5px 35px;
}

body cap-button {
  p-button {
    &.p-disabled {
      background: none;
      opacity: 1;
    }
  }
}
