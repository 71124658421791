ul {
  list-style: disc;
  padding-left: 30px;
  margin: 1em 0;
}

ol {
  list-style: decimal;
  padding-left: 26px;
  margin: 1em 0;

  li {
    padding-left: 4px;
  }
}
