@import 'abstracts';

body cap-dropdown p-dropdown .cap-dropdown.p-dropdown,
body cap-multi-select .cap-multi-select.p-multiselect {
  border-radius: 3px;
  border-color: $border-color;

  * {
    outline: none;
  }

  &,
  &.p-dropdown:not(.p-disabled):hover,
  &.p-dropdown:not(.p-disabled).p-focus,
  &.p-multiselect:not(.p-disabled):hover,
  &.p-multiselect:not(.p-disabled).p-focus {
    .p-dropdown-trigger,
    .p-multiselect-trigger {
      border-left: none;
    }
  }

  .p-dropdown-label,
  .p-multiselect-label {
    display: flex;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    border-left: none;

    .p-dropdown-trigger-icon,
    .p-multiselect-trigger-icon {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

body cap-dropdown .pi,
body cap-multi-select .pi {
  font-size: 0.75rem;
  font-weight: 600;
}

body .cap-rounded.cap-dropdown.p-dropdown,
body .cap-rounded.cap-multi-select.p-multiselect {
  border-color: $border-color;

  & .p-dropdown-label,
  & .p-multiselect-label {
    padding-left: 15px;

    & i {
      font-size: 1rem;
      color: rgba(var(--cap-primary-color-rgb, $primary-rgb), 0.5);
    }
  }

  & .cap-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item i {
    font-size: 1rem;
    color: var(--cap-primary-color, $primary);
  }

  &:hover,
  &:focus,
  &:active {
    border-color: var(--cap-primary-color, $primary);

    & .p-dropdown-label i,
    & .p-multiselect-label i {
      color: var(--cap-primary-color, $primary);
    }

    & .cap-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item i:hover {
      color: $white;
    }
  }
}

body .cap-rounded.p-dropdown.p-dropdown-open .p-dropdown-label i {
  color: var(--cap-primary-color, $primary);
}

body .cap-rounded.p-dropdown.p-dropdown-open .cap-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight i {
  color: $white;
}

body .cap-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  transition: ease-in-out all 0.15s;
  background-color: var(--cap-primary-color, $primary);
  color: $white;

  i {
    color: $white;
  }
}

body .has-icon.cap-multi-select.p-multiselect .p-multiselect-label,
body .has-icon.cap-dropdown.p-dropdown .p-dropdown-label {
  padding-left: 37px;
}

body .cap-dropdown-panel.p-dropdown-panel .p-dropdown-items li.p-dropdown-empty-message,
body .cap-multi-select-panel.p-multiselect-panel .p-multiselect-items li.p-multiselect-empty-message {
  padding: 0.5rem;
}

body .p-dropdown-clear-icon {
  top: 55%;
  right: 35px;

  &:hover {
    opacity: 0.5;
  }
}

.cap-multi-select .p-scroller,
.p-dropdown-panel .p-scroller {
  min-height: 250px;

  .p-multiselect-items,
  .p-dropdown-items {
    transform: none !important;
    top: 0;
    position: sticky;
  }
}

body .department-id-dropdown {
  .invalid-feedback {
    display: none !important;
  }
}
