/* General colors */
$primary: #b23129;
$primary-rgb: 178, 49, 41;
$secondary: #333;
$secondary-rgb: 51, 51, 51;

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;
$font-color: rgb(51, 51, 51);
$font-color-light: rgba(51, 51, 51, 0.5);
$font-awesome: 'Font Awesome 5 Pro';

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #f5f5f5;
$white: #fff;
$light-gray: rgb(221, 221, 221);
$success: #4cd964;

/* Overlaypanel menu-button */
$overlaypanel-text-color: #4f5152;
$overlaypanel-hover-background-color: rgb(211, 211, 211);

/* Inputs */
$cap-input-height: 33px;

/* Header */
$header-height: 75px;

/* Sidebar */
$sidebar-width: 325px;

/* Card */
$card-border-radius: 5px;
$card-title-color: #333;
$card-subtitle-color: #5c5c5c;
$card-description-color: #5c5c5c;
$card-compact-background-color: #f9f9f9;

/* Collapsable Card */
$collapsable-card-divider-border-color: #e7e7e7;
