@import 'abstracts';

:root {
  --cap-primary-color: $primary;
  --cap-primary-color-rgb: #{hextorgb($primary)};
}

.html-card {
  &__content {
    img {
      max-width: 100%;
    }
  }
}
