@import 'abstracts';

$chips-height: calc($cap-input-height + 5px);

body .p-inputgroup .p-inputgroup-addon {
  border-right: none;
  padding: 0;

  i.fas,
  i.far,
  i.fad {
    color: rgba(var(--cap-primary-color-rgb, $primary-rgb), 0.5);
    margin: 0 0 0 7.5px;
    font-size: 16px;
  }

  span {
    background-color: $border-color;
    width: 100%;
    height: 100%;
    padding: 0.55rem;
  }
}

body .p-inputgroup input {
  outline: none;
}

.hide-validator {
  cap-validator {
    visibility: hidden;
  }
}

body cap-textarea .cap-textarea {
  height: 100%;
}

cap-input.has-icon .p-inputgroup input.p-inputtext,
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error) {
  padding-left: 37px;
}

cap-input.cap-filter-input .p-inputgroup input.p-inputtext {
  min-width: 350px;
}

body .p-inputgroup input.p-inputtext.cap-rounded,
body .p-inputgroup input.p-inputtext:hover.cap-rounded,
body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error).cap-rounded,
body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error).cap-rounded {
  border-color: $border-color;

  &:hover {
    transition: ease-in-out all 0.15s;
    border-color: var(--cap-primary-color, $primary);
  }
}

body .device-files-upload cap-file-preview-list .cap-file-preview-list div.cap-file-preview-list__item {
  width: 100%;
  margin-right: 0;
  background-color: rgb(240, 240, 240);
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

body cap-chips p-chips .cap-chips.p-chips {
  width: 100%;
  height: $chips-height;
  border-radius: 3px;

  ul.p-inputtext.p-chips-multiple-container {
    display: flex;
    border-radius: 3px;
  }

  li.p-chips-token {
    border-radius: 25px;

    .p-chips-token-icon {
      margin-left: 7.5px;
    }
  }

  li.p-chips-input-token {
    height: calc($chips-height - 1px);
  }
}

body p-inputnumber input.p-inputnumber-input {
  padding: 9px;
  width: 100%;
  border-color: $border-color;
  border-radius: $cap-input-text-border-radius;
}

body p-inputmask input.p-inputmask {
  height: 38px;
  border: 1px solid $border-color;
  border-radius: 3px;
}

.no-content {
  display: block;
}
