.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin: auto;

  &__element {
    min-width: 200px;
    width: 350px;
    margin: 10px 15px;
  }
}

app-category-overview,
app-global-search-overview {
  .custom-row {
    row-gap: 15px;
    column-gap: 10px;

    &__element {
      margin: 0;

      app-device-card {
        display: contents;

        .device-card {
          height: 100%;

          &__expanded {
            display: flex;
            flex-direction: column;
            height: 100%;

            .content {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
