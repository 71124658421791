@import 'abstracts';

body .p-paginator-bottom.p-paginator button,
body .p-paginator-bottom.p-paginator .p-paginator-pages button.p-paginator-page {
  padding: 20px;
}

.cap-info-table.p-datatable .p-paginator {
  height: 150px;
}

.cap-info-table.p-datatable .p-paginator .p-paginator-prev:before {
  top: 6px;
  left: 15px;
}

p-paginator {
  anglelefticon,
  anglerighticon {
    display: none;
  }
}
