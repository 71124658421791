@import 'abstracts';

.sidebar-accordion-menu {
  a.p-accordion-header-link:hover {
    text-decoration: none;
  }

  span.p-accordion-toggle-icon,
  span.p-accordion-header-text {
    display: none;
  }
}
