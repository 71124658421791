@import 'abstracts';

$footer-height: 95px;
$header-height: 70px;

body .p-component-overlay {
  z-index: 98;
  background-color: rgba($black, 0.5);

  &-leave {
    display: none;
  }
}

.radboud-dialog.p-dialog {
  z-index: 100;
  width: 60%;
  height: 70%;
  left: 7.5%;
  top: 4.5%;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 5px 13px 0 rgba($black, 0.2);

  .p-dialog-content {
    border: none;
    border-radius: 5px;
    height: 100%;
    padding: 0;

    .radboud-dialog-wrapper {
      height: 100%;
      overflow: hidden;

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        border-bottom: 1px solid $border-color;
        position: relative;
        height: $header-height;
        max-height: $header-height;

        &__title {
          color: $font-color;
          font-size: 22px;
          font-weight: bold;
        }

        &__close-icon {
          position: absolute;
          top: 25px;
          right: 25px;
          color: $light-gray;
          font-size: 18px;

          & i:hover {
            transition: ease all 0.15s;
            cursor: pointer;
            color: $font-color;
          }
        }
      }

      &__content {
        padding: 2rem 1.75rem 0;
        overflow-y: auto;
        max-height: calc(100% - #{$footer-height + $header-height});
      }

      &__footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 0 1.75rem;
        height: $footer-height;
        max-height: $footer-height;
        background-color: $white;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        &__wrapper {
          width: 100%;
          padding: 1.75rem 0;
          border-top: 1px solid $border-color;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

.radboud-dialog.radboud-login-dialog.p-dialog {
  width: 50%;
  min-height: 650px;
  height: 60%;

  .radboud-dialog-wrapper {
    &__content {
      height: 100%;
      background: rgb(249, 249, 249);
    }
  }
}

.radboud-dialog.report-type-dialog.p-dialog {
  width: 40%;
  min-height: 400px;
  height: 50%;
}
